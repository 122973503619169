<template>
  <div class="view-home">
    <div class="container">
      <div class="test-results-block">
        <div class="successful-completion">
          <div class="successful-completion-img">
            <h1>
              {{$t('test_page_15')}}
            </h1>
            <img src="/images/successful-completion-img.svg" alt="">
          </div>
          <div class="successful-completion-right">
            <div class="successful-completion-block">
              <h2 style="color: #018837">
                {{$t('test_page_16')}}
              </h2>
              <h2>
                {{$t('test_page_17')}}
              </h2>

<!--              <div class="passed-test-list">-->
<!--                <div class="passed-test-item" style="background: #3377FF">-->
<!--                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <rect x="0.0234375" y="0.5" width="32" height="32" rx="16" fill="#039855" fill-opacity="0.11"/>-->
<!--                    <path d="M16.6907 9.83398L10.753 16.9592C10.5204 17.2383 10.4042 17.3778 10.4024 17.4956C10.4008 17.5981 10.4465 17.6955 10.5262 17.7599C10.6178 17.834 10.7994 17.834 11.1627 17.834H16.024L15.3573 23.1673L21.295 16.0421C21.5276 15.763 21.6438 15.6235 21.6456 15.5057C21.6472 15.4032 21.6015 15.3058 21.5218 15.2414C21.4302 15.1673 21.2485 15.1673 20.8853 15.1673H16.024L16.6907 9.83398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg>-->
<!--                  <p class="number">18</p>-->
<!--                  <p class="title">Логическое мышление</p>-->
<!--                </div>-->

<!--                <div class="passed-test-item" style="background: #8C76FF">-->
<!--                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <rect x="0.0234375" y="0.5" width="32" height="32" rx="16" fill="#039855" fill-opacity="0.11"/>-->
<!--                    <path d="M16.6907 9.83398L10.753 16.9592C10.5204 17.2383 10.4042 17.3778 10.4024 17.4956C10.4008 17.5981 10.4465 17.6955 10.5262 17.7599C10.6178 17.834 10.7994 17.834 11.1627 17.834H16.024L15.3573 23.1673L21.295 16.0421C21.5276 15.763 21.6438 15.6235 21.6456 15.5057C21.6472 15.4032 21.6015 15.3058 21.5218 15.2414C21.4302 15.1673 21.2485 15.1673 20.8853 15.1673H16.024L16.6907 9.83398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg>-->
<!--                  <p class="number">20</p>-->
<!--                  <p class="title">Пространственное мышление</p>-->
<!--                </div>-->

<!--                <div class="passed-test-item" style="background: #69D0CA">-->
<!--                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <rect x="0.0234375" y="0.5" width="32" height="32" rx="16" fill="#039855" fill-opacity="0.11"/>-->
<!--                    <path d="M16.6907 9.83398L10.753 16.9592C10.5204 17.2383 10.4042 17.3778 10.4024 17.4956C10.4008 17.5981 10.4465 17.6955 10.5262 17.7599C10.6178 17.834 10.7994 17.834 11.1627 17.834H16.024L15.3573 23.1673L21.295 16.0421C21.5276 15.763 21.6438 15.6235 21.6456 15.5057C21.6472 15.4032 21.6015 15.3058 21.5218 15.2414C21.4302 15.1673 21.2485 15.1673 20.8853 15.1673H16.024L16.6907 9.83398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg>-->
<!--                  <p class="number">17</p>-->
<!--                  <p class="title">Логическое мышление</p>-->
<!--                </div>-->

<!--                <div class="passed-test-item" style="background: #FC9A81">-->
<!--                  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                    <rect x="0.0234375" y="0.5" width="32" height="32" rx="16" fill="#039855" fill-opacity="0.11"/>-->
<!--                    <path d="M16.6907 9.83398L10.753 16.9592C10.5204 17.2383 10.4042 17.3778 10.4024 17.4956C10.4008 17.5981 10.4465 17.6955 10.5262 17.7599C10.6178 17.834 10.7994 17.834 11.1627 17.834H16.024L15.3573 23.1673L21.295 16.0421C21.5276 15.763 21.6438 15.6235 21.6456 15.5057C21.6472 15.4032 21.6015 15.3058 21.5218 15.2414C21.4302 15.1673 21.2485 15.1673 20.8853 15.1673H16.024L16.6907 9.83398Z" stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  </svg>-->
<!--                  <p class="number">15</p>-->
<!--                  <p class="title">Пространственное мышление</p>-->
<!--                </div>-->


<!--              </div>-->

              <div class="passed-test-list-desktop">
                <div class="score-container">
                  <div
                      v-for="(item, index) in quizResult.subject_results"
                      :key="index"
                      :class="['score-box', index % 2 === 0 ? 'blue-block' : 'purple-block']"
                  >
                    <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="0.5" width="32" height="32" rx="16" :fill="index % 2 === 0 ? '#0055FF' : '#6C5DB9'"/>
                      <path
                          v-if="index % 2 === 0"
                          d="M17.1672 9.33398L11.2295 16.4592C10.997 16.7383 10.8807 16.8778 10.8789 16.9956C10.8774 17.0981 10.923 17.1955 11.0027 17.2599C11.0944 17.334 11.276 17.334 11.6392 17.334H16.5006L15.8339 22.6673L21.7716 15.5421C22.0041 15.263 22.1204 15.1235 22.1222 15.0057C22.1237 14.9032 22.0781 14.8058 21.9984 14.7414C21.9067 14.6673 21.7251 14.6673 21.3619 14.6673H16.5006L17.1672 9.33398Z"
                          stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"
                      />
                      <path
                          v-else
                          d="M9.83398 17.6664L16.2621 20.8805C16.3496 20.9242 16.3933 20.9461 16.4392 20.9547C16.4798 20.9623 16.5215 20.9623 16.5621 20.9547C16.608 20.9461 16.6517 20.9242 16.7392 20.8805L23.1673 17.6664M9.83398 14.3331L16.2621 11.119C16.3496 11.0752 16.3933 11.0534 16.4392 11.0448C16.4798 11.0372 16.5215 11.0372 16.5621 11.0448C16.608 11.0534 16.6517 11.0752 16.7392 11.119L23.1673 14.3331L16.7392 17.5471C16.6517 17.5909 16.608 17.6127 16.5621 17.6213C16.5215 17.6289 16.4798 17.6289 16.4392 17.6213C16.3933 17.6127 16.3496 17.5909 16.2621 17.5471L9.83398 14.3331Z"
                          stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"
                      />
                    </svg>
                    <p class="score">{{ item.result }}</p>
                    <p class="label">{{ item[`name_${test_lang}`] }}</p>
                  </div>
                </div>
              </div>

              <div class="final-score-desktop">
                <div class="score">{{ quizResult.result }}</div>
                <div class="label">{{$t('test_page_18')}}</div>
              </div>

              <a class="return-profile-link" href="https://1000bala.elumiti.kz/testing">
                {{$t('test_page_19')}}
              </a>
            </div>

            <div class="call-center">
              <p>
                {{$t('test_page_20')}}
              </p>
              <p class="phone-number">
                <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.51 12.38C16.28 12.38 15.09 12.18 13.98 11.82C13.63 11.7 13.24 11.79 12.97 12.06L11.4 14.03C8.57 12.68 5.92 10.13 4.51 7.2L6.46 5.54C6.73 5.26 6.81 4.87 6.7 4.52C6.33 3.41 6.14 2.22 6.14 0.99C6.14 0.45 5.69 0 5.15 0H1.69C1.15 0 0.5 0.24 0.5 0.99C0.5 10.28 8.23 18 17.51 18C18.22 18 18.5 17.37 18.5 16.82V13.37C18.5 12.83 18.05 12.38 17.51 12.38Z" fill="#E24C4B"/>
                </svg>
                8 727 310 02 58
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="test-results-mobile">
      <div class="test-results-header-mobile">
        <a class="link-profile" href="https://1000bala.elumiti.kz/testing">
          Личный кабинет
        </a>
        <h2>
          Результаты тестирования
        </h2>
      </div>

      <div class="result-container">
        <h2 class="success-message">Поздравляем!</h2>
        <p class="description">
          Вы успешно завершили тестирование 1 этапа олимпиады “Мын бала”.
        </p>

        <div class="score-container">
          <div
              v-for="(item, index) in quizResult.subject_results"
              :key="index"
              :class="['score-box', index % 2 === 0 ? 'blue-block' : 'purple-block']"
          >
            <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0.5" width="32" height="32" rx="16" :fill="index % 2 === 0 ? '#0055FF' : '#6C5DB9'"/>
              <path
                  v-if="index % 2 === 0"
                  d="M17.1672 9.33398L11.2295 16.4592C10.997 16.7383 10.8807 16.8778 10.8789 16.9956C10.8774 17.0981 10.923 17.1955 11.0027 17.2599C11.0944 17.334 11.276 17.334 11.6392 17.334H16.5006L15.8339 22.6673L21.7716 15.5421C22.0041 15.263 22.1204 15.1235 22.1222 15.0057C22.1237 14.9032 22.0781 14.8058 21.9984 14.7414C21.9067 14.6673 21.7251 14.6673 21.3619 14.6673H16.5006L17.1672 9.33398Z"
                  stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"
              />
              <path
                  v-else
                  d="M9.83398 17.6664L16.2621 20.8805C16.3496 20.9242 16.3933 20.9461 16.4392 20.9547C16.4798 20.9623 16.5215 20.9623 16.5621 20.9547C16.608 20.9461 16.6517 20.9242 16.7392 20.8805L23.1673 17.6664M9.83398 14.3331L16.2621 11.119C16.3496 11.0752 16.3933 11.0534 16.4392 11.0448C16.4798 11.0372 16.5215 11.0372 16.5621 11.0448C16.608 11.0534 16.6517 11.0752 16.7392 11.119L23.1673 14.3331L16.7392 17.5471C16.6517 17.5909 16.608 17.6127 16.5621 17.6213C16.5215 17.6289 16.4798 17.6289 16.4392 17.6213C16.3933 17.6127 16.3496 17.5909 16.2621 17.5471L9.83398 14.3331Z"
                  stroke="white" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"
              />
            </svg>
            <p class="score">{{ item.result }}</p>
            <p class="label">{{ item[`name_${test_lang}`] }}</p>
          </div>
        </div>

        <div class="final-score">
          <div class="final-score-left">
            <p class="score">{{ quizResult.result }}</p>
            <p class="label">Итоговый балл</p>
          </div>
          <div class="final-score-right">
            <img src="/images/test-result-image.svg" width="142" height="92" alt="">


          </div>
        </div>

        <div class="contact">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.01 12.38C15.78 12.38 14.59 12.18 13.48 11.82C13.13 11.7 12.74 11.79 12.47 12.06L10.9 14.03C8.07 12.68 5.42 10.13 4.01 7.2L5.96 5.54C6.23 5.26 6.31 4.87 6.2 4.52C5.83 3.41 5.64 2.22 5.64 0.99C5.64 0.45 5.19 0 4.65 0H1.19C0.65 0 0 0.24 0 0.99C0 10.28 7.73 18 17.01 18C17.72 18 18 17.37 18 16.82V13.37C18 12.83 17.55 12.38 17.01 12.38Z" fill="#E24C4B"/>
          </svg>

          8 727 310 02 58
        </div>

        <button class="back-button" @click="backProfile">Назад в Личный кабинет</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TestResults",
  data() {
    return {
      quizResult: {
        subject_results: [],
        result: 0,
      },
      test_lang: 'ru',
    }
  },
  methods: {
    backProfile() {
      window.location.href = "https://1000bala.elumiti.kz/testing"
    }
  },
  mounted() {

    if (localStorage.getItem('test_lang')) {
      this.test_lang = localStorage.getItem('test_lang');
      this.$i18n.locale = this.test_lang;
    }

    const quizToken = localStorage.getItem('quiz-token')

    this.$http.get(`${API_ROOT}/api/quiz/result/${quizToken}`)
        .then(res => {
          this.quizResult = res.body.data
          console.log(1111111111111, this.quizResult)
        })
  }
}
</script>

<style>

</style>